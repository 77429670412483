<template>
    <layout :classes="classes" :routeName="routeName">
        <div class="row">
            <div class="content">
                <div class="form-container">
                    <div class="title-container">
                        <h3 class="heading">{{$t('authentication.validateEmailAddress.title')}}</h3>
                    </div>
                    <div class="form login validate-email">
                        <div class="form-content">
                            <span v-if="loading" class="cta loading"></span>
                        </div>
                    </div>
                    
                    <p class="back-to-login"><router-link class="cta cta-link light" to="/dashboard">{{$t('navigation.back')}}</router-link></p>
                </div>
            </div>

            <div class="lb-bg"></div>
            
            <img v-if="$screen.width <= 768" class="bottom-bg mobile" src="../../../assets/images/backgrounds/bg_auth_mobile.svg" alt="abstract background">
            <img v-else class="bottom-bg desktop" src="../../../assets/images/backgrounds/bg_auth_desktop.svg" alt="abstract background">
        </div>
    </layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import Layout from "../../../components/Layout.vue";

export default {
    name: "validate-email-address",
    components: {
        Layout
    },
    props: {
        token: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            routeName: "validate-email-address",
            classes: { login: true },
            loading: false
        }
    },

    computed: {
        ...mapGetters([
            'is_authenticated'
        ])
    },

    methods: {
        ...mapActions([
            'logout',
            'setToken'
        ]),

        redirect: function() {
            const self = this;
            self.$utils._navigate_to_name('dashboard');
        },

        verifyAccount: function() {
            const self = this;

            if(self.token) {
                self.loading = true;
                const data = {
                    token: self.token
                };

                self.$axios
                    .post('/signup/validate', data)
                    .then(response => {
                        if(response.status == 200) {
                            self.loading = false; 
                            self.success = true;    

                            if(self.is_authenticated) {
                                self.setToken(response.data);
                            }
                            
                            self.$toasted.success(
                                self.$t('authentication.validateEmailAddress.success'),
                                    { icon: 'circle-check', duration: '5000' }
                            );
                            
                            self.redirect();
                        }
                    })
                    .catch(() => {
                        self.loading = false; 
                        self.success = false;

                        self.$toasted.error(
                            self.$t('authentication.validateEmailAddress.linkInvalid'),
                                { icon: 'circle-exclamation', duration: '5000' }
                        );

                        self.redirect();
                    });
            }
        }
    },

    mounted() {
        const self = this;

        if(!self.token) {
            self.$utils._navigate_to_name('login')
        } else {
            self.verifyAccount();
        }
    }
};
</script>